<template>
  <div class="user-evaluate-tc">
    <!-- 头部 -->
    <div class="header">
      <div class="header-operation">
        <ul>
          <li>
            <router-link to="/user_evaluate/addUser">
              <img src="../../../assets/images/1.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/user_evaluate/bulkImport">
              <img src="../../../assets/images/2.png" alt />
            </router-link>
          </li>
          <li>
            <router-link to="/user_evaluate/exportUserInfo">
              <img src="../../../assets/images/3.png" alt />
            </router-link>
          </li>
          <li @click="showbatchSet" class="batchSet">
            <!-- <router-link to="/user_evaluate/batchSetPermissions"> -->
            <img src="../../../assets/images/6.png" height="148.78" alt />
            <!-- </router-link> -->
          </li>
          <li>
            <router-link to="/user_evaluate/custom">
              <img src="../../../assets/images/5.png" alt />
            </router-link>
          </li>
        </ul>
      </div>
      <div class="header-tool">
        <div class="del" @click="delDatas">
          <img src="../../../assets/images/del.png" alt />
        </div>
        <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="部门" prop="bumen">
            <el-select v-model="formInline.bumen">
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="formInline.sex">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="0"></el-option>
            </el-select>
          </el-form-item>
          <!-- 2022年10月31日 唐加宇 需求要隐藏掉 -->
          <el-form-item label="出生范围" prop="date" v-if="false">
            <!-- <span class="demonstration">出生范围</span> -->
            <el-date-picker
              v-model="formInline.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              >></el-date-picker
            >
          </el-form-item>
          <el-form-item label="姓名" prop="user" >
            <el-input v-model="formInline.user"></el-input>
          </el-form-item>
<!--          <el-form-item label="身份证号" prop="idcard">-->
<!--            <el-input v-model="formInline.idcard"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="用户编号" prop="account">
            <el-input v-model="formInline.account"></el-input>
          </el-form-item>
          <el-form-item label="文化程度" prop="wenhua">
            <el-select v-model="formInline.wenhua">
              <el-option label="文盲" value="文盲"></el-option>
              <el-option label="小学" value="小学"></el-option>
              <el-option label="初中" value="初中"></el-option>
              <el-option
                label="高中/中专/技校"
                value="高中/中专/技校"
              ></el-option>
              <el-option label="大学专科" value="大学专科"></el-option>
              <el-option label="大学本科" value="大学本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士及以上" value="博士及以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="570"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="用户编号"
          width="230"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="姓名"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="department_name"
          label="部门名称"
          width="250"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex | sexFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.age | ageFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="最近登陆"
          width="250"
          align="center"
        >
          <template slot-scope="scope">
            <!-- 1970-01-01 08:00:00 -->
            <span>{{
              scope.row.create_time == "1970-01-01 08:00:00"
                ? "未登录"
                : scope.row.create_time
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button size="mini">趋势</el-button>
            <el-divider direction="vertical"></el-divider>-->
            <el-button size="mini" @click="goReport(scope.row)">报告</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" @click="edit(scope.row.id)">编辑</el-button>
            <el-button size="mini" @click="switchover_user(scope.row.id)"
              >测试</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page" v-if="showPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 批量设置用户权限模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title=" 批量设置用户"
      :visible.sync="batchSetBox"
      width="1200px"
      center
    >
      <!-- 量表权限 -->
      <el-form ref="form" :model="form" label-width="80px" size="mini">
        <el-tabs v-model="scale">
          <el-tab-pane label="所有可用量表" name="first">
            <div class="query">
              <el-form :inline="true" :model="form" size="mini">
                <el-form-item>
                  <el-input
                    v-model="form.scaleName"
                    placeholder="量表名称"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="queryScale()"
                    >量表查询</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="checkAll(1)"
                    >全选或取消所有量表</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="checkAll(2)"
                    >全选或取消测评人查看结果</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <!-- 所有量表 -->
            <div class="table" @change="handleCheckAllChange">
              <table>
                <tr>
                  <th>量表名称</th>
                  <th>使用该量表</th>
                  <th>允许测评人看测评结果</th>
                  <th>测评次数</th>
                </tr>
                <template v-for="(item, key) in scaleData">
                  <tr
                    class="title"
                    :key="key"
                    v-if="item.status_measure.length > 0"
                  >
                    <td colspan="4">{{ item.classify_name }}</td>
                  </tr>
                  <tr
                    v-for="item2 in item.status_measure"
                    :key="item2.classify_name"
                  >
                    <td>{{ item2.measure_title }}</td>
                    <td>
                      <el-checkbox-group v-model="checkList">
                        <el-checkbox :label="item2.id" name="a"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                    <td>
                      <el-checkbox-group v-model="checkList2">
                        <el-checkbox :label="item2.id" name="b"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                    <td>
                      <el-input
                        v-model="item2.max_num"
                        placeholder=""
                        size="mini"
                        :name="item2.id"
                        maxlength="2"
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                      ></el-input>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <el-col :span="12">
              <el-form-item label="已测量表是否显示" label-width="150px">
                <el-radio-group v-model="form.show_finish_measure">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="量表组合包" name="second">
            <div class="query">
              <p>量表分组设置</p>
            </div>
            <div class="table">
              <table @change="handleCheckAllChange2">
                <tr>
                  <th>分组量表名称</th>
                  <th>量表数量</th>
                  <!-- <th>次数限制</th> -->
                  <th>应用该分组</th>
                </tr>
                <tr v-for="(item, key) in scaleData2.data" :key="key">
                  <td>{{ item.group_name }}</td>
                  <td>{{ item.measure_count }}</td>
                  <!-- <td>{{ item.test_num }}</td> -->

                  <td>
                    <el-checkbox-group v-model="checkList3">
                      <el-checkbox :label="item.id"></el-checkbox>
                    </el-checkbox-group>
                  </td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="部门更换" name="three">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              size="mini"
              ref="formInline"
            >
              <el-form-item label="部门：" prop="department">
                <el-select v-model="formInline.department">
                  <el-option
                    v-for="item in departmentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    >{{ item.name }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <el-row v-if="scale != 'three'">
          <el-col :span="10">
            <el-form-item label="测评时间范围" label-width="150px" prop="date">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchSetBox = false">取 消</el-button>
        <el-button type="primary" @click="batchSet">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看用户报告模态框 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="userMeasureBox"
      width="70%"
      center
    >
      <!-- 表格 -->
      <div class="content">
        <el-table
          ref="multipleTable"
          :data="userMeasureData"
          tooltip-effect="dark"
          style="width: 100%"
          max-height="570"
          stripe
          border
          class="tableBox"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="measure_title"
            label="量表名称"
            align="left"
            width="400"
            header-align="center"
          ></el-table-column>
          <el-table-column
            prop="department_name"
            label="部门名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name"
            label="姓名"
            align="center"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="50" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.sex | sexFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="user_account"
            label="用户编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="测评日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="warning"
            label="预警"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <span :class="scope.row.warning == 1 ? 'off' : 'no'">{{
                scope.row.warning == 1 ? "预警" : "正常"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="inspect"
            label="审核状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span
                :class="scope.row.inspect == 0 ? 'off' : 'no'"
                @click="upInspect(scope.row)"
                >{{ scope.row.inspect === 1 ? "通过审核" : "未审核" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="plain"
                @click="goReport(scope.row.id)"
                >查看</el-button
              >
              <!-- <el-divider direction="vertical"></el-divider>
              <el-button size="mini" type="plain" @click="edit(scope.row.id)">编辑</el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userMeasureBox = false">取 消</el-button>
        <el-button type="primary" @click="userMeasureBox = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { indexMeasure } from "@/api/information.js";
import { loadDepartmentData } from "@/api/comm.js";
import {
  loadUserData,
  delUser,
  userAccess,
  switchoverUser,
} from "@/api/evaluate.js";
import moment from "moment";
import axios from "axios";
export default {
  components: {},
  inject: ["reload"],
  data() {
    return {
      showPage: true,
      loading: true,
      userMeasureBox: false,
      userMeasureData: [],
      update: true,
      transitionName: "",
      //筛选表单
      formInline: {
        user: "",
        region: "",
        sex: "",
        idcard: "",
        bumen: "",
        chushenL: "",
        wenhua: "",
        date: " ",
        account: "",
        department: "",
      },
      // 部门
      departmentList: [],
      // 表格
      tableData: [],
      total: 0, //总数
      page: 1, //页码
      pageSize: 15, //当前页码
      multipleSelection: [],
      batchSetBox: false,
      // 模态框数据
      form: {
        date: [],
        scaleName: "",
        show_finish_measure: 0,
        measure: [],
      },

      // -------------------
      // 用户tab栏
      activeName: "first",
      // 量表tab栏
      scale: "first",
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 量表组合包-是否使用
      checkList3: [],
      //量表组合包数据
      scaleData2: [],
      // 所有量表-全选控制
      checkAll1: 1,
      checkAll2: 1,
    };
  },
  created() {
    // 判断存在 缓存数据，有则加载
    let isExportUser = JSON.parse(localStorage.getItem("exportUser"));
    if (isExportUser) {
      this.formInline = isExportUser.filter;
      this.page = isExportUser.page;
      this.pageSize = isExportUser.pagesize;
      localStorage.removeItem("exportUser");
    }
    // 加载用户数据
    this.loadUsers();
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
      // 存储数据
      localStorage.setItem("departmentList", JSON.stringify(res.data.data));
    });
    this.loadScaleData();
    this.loadScaleData2();
  },
  methods: {
    // 提交
    onSubmit() {
      this.loading = true;
      this.showPage = false;
      this.loadUsers(1);
      this.$message({
        message: "查询成功",
        type: "success",
      });
      this.$nextTick(() => {
        this.showPage = true;
      });
    },
    // 重置
    resetForm(formName) {
      // this.$nextTick(() => {
      //   this.$refs[formName].resetFields();
      // });
      this.formInline = {
        user: "",
        region: "",
        sex: "",
        idcard: "",
        bumen: "",
        chushenL: "",
        wenhua: "",
        date: " ",
        account: "",
        department: "",
      };
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
      ////console.log(p);
    },
    // 当前页面获取的最大数量
    handleSizeChange(val) {
      this.loading = true;
      this.pageSize = val;
      this.loadUsers();
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.loading = true;
      this.page = val;
      this.loadUsers();
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 用户列表查询/请求数据
    async loadUsers(val) {
      // 数据处理
      let {
        user,
        region,
        sex,
        idcard,
        bumen,
        chushenL,
        wenhua,
        date,
        account,
      } = this.formInline;

      date = date ?? "";
      if (val == 1) {
        this.page = 1;
      }
      let data = {
        page: this.page,
        pagesize: this.pageSize,
        real_name: user,
        sex: sex,
        idcard: idcard,
        agemin: date[0] || "",
        agemax: date[1] || "999999999999",
        department_id: bumen,
        culture: wenhua,
        account: this.formInline.account,
      };

      // 发送数据
      loadUserData(data).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
        this.loading = false;
        // this.showPage = true;
      });
    },
    // 编辑页面转跳
    edit(id) {
      this.$router.push(`/user_evaluate/editUser?id=${id}`);
    },
    // 切换到用户测试页面
    switchover_user(data) {
      switchoverUser({ user_id: data }).then((res) => {
        if (res.code == 400200) {
          localStorage.removeItem("tableData"); // 生成一个切换的标识
          localStorage.setItem("switchover", res.switchover); // 生成一个切换的标识
          this.$router.push(`/answer_content/answer_list`);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 报告页面转跳
    goReport(row) {
      // 保存当前数据
      let data = {
        page: this.page,
        pagesize: this.pageSize,
        filter: this.formInline,
      };
      localStorage.setItem("exportUser", JSON.stringify(data));
      // 跳转
      this.$router.push(`/evaluate_report?id=${row.id}`);
    },
    showReport(row) {
      this.userMeasureBox = true;
      let obj = {
        page: 1,
        pageSize: 99999,
        uid: row.id,
        account: "",
        real_name: "",
        measure_title: "",
        department_id: "",
      };
      indexMeasure(obj).then((res) => {
        this.$nextTick(() => {
          this.userMeasureData = res.data;
        });
      });
    },
    // 全选删除
    delDatas() {
      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning",
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的用户?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = this.multipleSelection;
          data = data.toString();
          delUser({ id: data }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "批量删除成功",
                type: "success",
              });
            }
            this.loadUsers();
          });
        })
        .catch(() => {});
    },
    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      if (val.target.name === "a") {
        var obj = { measure_id: a, show_result: 0 };
        var tmp = this.form.measure.some(function (item) {
          return item.measure_id === a;
        });
        if (tmp) {
          this.form.measure = this.form.measure.filter((v) => {
            return v.measure_id != val.target.value;
          });
        } else {
          this.form.measure.push(obj);
        }
      } else if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        var index = this.form.measure.findIndex((item) => {
          return item.measure_id === a;
        });
        if (this.form.measure[index] && index != -1) {
          if (this.form.measure[index].show_result === 0) {
            this.form.measure[index].show_result = 1;
          } else {
            this.form.measure[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      } else {
        let st = true;
        this.form.measure.forEach((v) => {
          if (v.measure_id == val.target.name) {
            v.max_num = val.target.value || 0;
            st = false;
          }
        });
        if (st) {
          this.$message({
            message: "警告,量表未勾选",
            type: "warning",
          });
          val.target.value = "";
        }
      }
    },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res === 1) {
        if (this.checkAll1 === 1) {
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { measure_id: v.id, show_result: 0 };
              this.form.measure.push(obj);
            });
          });
          this.checkAll1 = 0;
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.form.measure = [];
          this.checkAll1 = 1;
        }
      } else {
        if (this.checkAll2 === 1) {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 0;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 组合量表
    handleCheckAllChange2(val) {
      // 清除所有;
      this.checkList3 = [];
      this.checkList3.push(parseInt(val.target.value));
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        this.scaleData1 = res.data.data;
      } else {
        return;
      }
    },
    //组合量表包请求
    async loadScaleData2() {
      var res = await axios.get("/list_measure_group");
      this.scaleData2 = res.data.data;
      //console.log("组合", this.scaleData2);
    },
    // 量表查询
    queryScale() {
      var str = this.form.scaleName;
      if (str === "") {
        this.scaleData = this.scaleData1;
        //console.log("没值");
        return;
      }
      var obj = [{ classify_name: "查询结果", id: 1, status_measure: [] }];
      //console.log("this.scaleData1:", this.scaleData1);
      for (let k in this.scaleData1) {
        if (this.scaleData1[k].id) {
          //console.log("k", this.scaleData1[k]);
          this.scaleData1[k].status_measure.forEach((v) => {
            if (v.measure_title.indexOf(str) != -1) {
              obj[0].status_measure.push(v);
            }
          });
        }
      }
      this.scaleData = obj;
    },
    // 显示模态框
    showbatchSet() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要设置的用户",
          type: "warning",
        });
        return;
      } else {
        this.batchSetBox = true;
      }
    },
    // 批量设置用户权限
    batchSet() {
      // 数据处理
      let begin_time = 0;
      let end_time = 0;
      if (this.form.date) {
        this.form.begin_time = parseInt(this.form.date[0] / 1000);
        this.form.end_time = parseInt(this.form.date[1] / 1000);
      }
      let obj = {};
      delete this.form.date;
      delete this.form.scaleName;
      if (this.scale === "first") {
        delete this.form.measure_group;
        // 所有量表
        var { show_finish_measure, measure } = this.form;
        this.form.show_finish_measure = show_finish_measure;
      } else if (this.scale === "second") {
        // 量表组
        delete this.form.measure;
        this.form.measure_group = this.checkList3[0];
      }
      // 提交数据判断
      let data = {};
      if (this.scale == "three") {
        data.department_id = this.formInline.department;
      } else {
        data = this.form;
      }
      data.user_id = this.multipleSelection.toString();
      userAccess(data).then((res) => {
        //console.log(res);
        if (res.code == 400200) {
          this.$message({
            message: "批量设置权限成功",
            type: "success",
          });
        }
        this.batchSetBox = false;
        this.loadUsers();
      });
    },
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  filters: {
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
    // 年龄过滤器
    ageFilter(val) {
      let birthdays = new Date(val.replace(/-/g, "/"));
      let d = new Date();
      let age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      if (age <= 0) {
        return 0;
      } else {
        return age;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "editUser") {
      to.meta.ifDoFresh = false;
    }
    next();
  },
  activated() {
    //开启了keepAlive:true后再次进入，以前的搜索条件和页数都不会变，无论什么情况都调用一下获取数据的接口，这样就能得到当前搜索条件和页数的最新数据
    if (this.$route.meta.ifDoFresh) {
      //重置ifDoFresh
      this.$route.meta.ifDoFresh = false; //获取列表数据方法第一参数为是否重置搜索条件和页数
      // this.loadUsers();
    } else {
      // this.loadUsers();
      this.loadUsers();
    }
  },
};
</script>

<style lang="less">
.user-evaluate-tc {
  width: 100%;
  min-width: 1370px;
  height: calc(~"100% - 70px");
  div {
    box-sizing: border-box;
  }
  .breadcrumb-container {
    width: 100%;
    border-bottom: 1px rgb(154, 220, 248) solid;
  }
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 5px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 150px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
        .batchSet {
          cursor: pointer;
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(1) .el-input__inner {
        width: 100px;
        padding-right: 0;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        width: 100px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
    }
  }
  // 表格
  .tableBox {
    margin-top: 20px;
    color: rgb(4, 87, 102);
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  // 表格容器
  .content {
    // position: relative;
    // height: calc(~"100% - 220px");
    // min-height: 600px;
    min-height: 570px;
  }
  // 模态框
  .el-dialog__wrapper {
    .el-form-item:nth-child(3) .el-input__inner {
      width: 230px;
      padding-right: 0;
    }
    .table {
      width: 950px;
      height: 300px;
      overflow: auto;
      border: 1px #cccccc solid;
      margin-bottom: 20px;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }

      table {
        width: 945px;
        text-align: center;
        overflow: auto;
        border-collapse: collapse;
        th {
          border-bottom: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
        }
        tr,
        th {
          height: 20px;
          padding: 0;
          font-weight: 300;
          font-size: 14px;
        }
        tr {
          td {
            padding: 2px 5px;
            &:nth-child(1) {
              text-align: left;
            }
            .el-input-number {
              .el-input__inner {
                padding: 0;
              }
            }
          }
          &:hover {
            background: #b4e7e9;
          }
        }
        th {
          height: 30px;
          font-size: 15px;
          background: rgb(224, 245, 250);
        }
        .title {
          background: rgb(224, 245, 250);
          text-align: left;
          font-weight: 700;
          font-size: 15px;
        }
        .el-checkbox__label {
          display: none;
        }
      }
    }
  }
}
</style>
